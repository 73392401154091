import * as React from "react"
import Layout from "@components/layout"
import Hero from "@components/hero"
import Marquee from "@components/marquee"
import Footer from "@components/footer"
import Body from '@components/body'
import {Link} from 'gatsby'

const NotFoundPage = () => {
  return (

    <Layout>
      <Hero />
      <Marquee />
      <Body>
        <div className="max-w-xl mx-auto min-h-90 p-10 py-40">
          <h1 className="text-4xl font-black text-prussian"> 404 - <span className="text-outline text-prussian">Not found</span></h1>
          <p className="text-xl text-prussian">Sad to say but there's absolutely nothing to see here, <Link to="/" className="underline hover:text-gold">try coming back.</Link></p>
        </div>
      
      </Body>
      <Footer />
    </Layout>
  )
}

export default NotFoundPage